'use client';

import animationData from '@/animations/feature-help-desk.json';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { FC, useEffect, useRef } from 'react';

export interface AnimationFeatureHelpDeskProps {
  playing?: boolean;
}

const AnimationFeatureHelpDesk: FC<AnimationFeatureHelpDeskProps> = ({ playing }) => {
  const animationRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (!animationRef.current) return;

    if (playing) {
      animationRef.current.play();
    } else {
      animationRef.current.pause();
    }
  }, [playing]);

  return (
    <div className="aspect-[396/348]">
      <Lottie animationData={animationData} autoplay={false} lottieRef={animationRef} />
    </div>
  );
};

export default AnimationFeatureHelpDesk;
