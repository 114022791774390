'use client';

import ease from '@/constants/ease';
import useStore from '@/stores/useStore';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useState } from 'react';
import { useIntersectionObserverRef } from 'rooks';

export interface CtaLinkProps {
  type: 'primary' | 'secondary';
  title: string;
  href: string;
  action: 'trial' | 'demo';
}

export interface PageBumperProps {
  title: string;
  links: CtaLinkProps[];
}

export const PageBumper: FC<PageBumperProps> = ({ title, links = [] }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasSeen, setHasSeen] = useState(false);
  const { setDemoModalOpen, setTrialModalOpen } = useStore();

  const callback = (entries) => {
    let isIntersecting = false;

    if (entries && entries[0]) {
      isIntersecting = entries[0].isIntersecting;
    }

    setIsVisible(isIntersecting);

    if (isIntersecting) {
      setHasSeen(true);
    }
  };

  const modalActionHandler = (action) => {
    switch (action) {
      case 'trial':
        setTrialModalOpen(true);
        break;
      case 'demo':
        setDemoModalOpen(true);
        break;
      default:
        break;
    }
  };

  const [ref] = useIntersectionObserverRef(callback, { threshold: 0.1 });

  const ctaCol = (
    <div className="xl:col-span-7">
      <div className="bg-citron-500 rounded-[12px]">
        <div className="px-3 pt-3 lg:px-10 lg:py-7">
          <div className="flex flex-wrap flex-col gap-3 md:gap-2">
            <p className="font-500 text-30/35 md:text-36/42 tracking-tight">{title}</p>
            <ul className="flex md:flex-row items-start md:items-center">
              {links.map((link, index) => (
                <li key={`bumper-link-${link.title?.replace(/\s/g, '-') ?? index}`}>
                  {link.href.length ? (
                    <Link
                      className={cx('block', {
                        'button button-size-medium button-primary-violet': link.type === 'primary',
                        'button button-size-medium button-secondary-violet': link.type === 'secondary',
                      })}
                      href={link.href}
                    >
                      {link.title}
                    </Link>
                  ) : (
                    <button
                      className={cx('block', {
                        'button button-size-medium button-primary-blue-900': link.type === 'primary',
                        'button button-size-medium button-secondary-blue-900 !ml-[12px]': link.type === 'secondary',
                      })}
                      onClick={() => {
                        modalActionHandler(link.action);
                      }}
                    >
                      {link.title}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="px-[8px] pb-[8px]">
          <div className="relative mt-[40px]">
            <div className="inset-0 flex md:hidden overflow-hidden rounded-10 mt-3">
              <Image
                src="/images/bumper-image.jpg"
                alt=""
                className="w-full"
                width={585}
                height={322}
              />
            </div>
            <div className="absolute inset-0 flex md:hidden flex-col justify-end items-center pb-[5%]">
              <AnimatePresence>
                {hasSeen && (
                  <motion.div
                    initial={{ opacity: 0, y: '100%' }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      ease: ease.appear,
                      duration: 400 / 1000,
                      delay: 800 / 1000,
                    }}
                    className="px-[8%]"
                  >
                    <Image src="/images/bumper-notification.svg" alt="" width={430} height={63} className="w-full h-auto" />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const animationColumn = (
    <div className="relative hidden xl:block col-span-5 pointer-events-none ml-[-8px]">
      <div className="inset-0 overflow-hidden rounded-[12px]">
        <Image
          src="/images/bumper-image.jpg"
          alt=""
          className="min-h-[300px] w-full"
          width={585}
          height={322}
        />
      </div>
      <div className="absolute inset-0 flex flex-col justify-end items-center pb-[15%]">
        <AnimatePresence>
          {hasSeen && (
            <motion.div
              initial={{ opacity: 0, y: '100%' }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                ease: ease.appear,
                duration: 400 / 1000,
                delay: 800 / 1000,
              }}
              className="px-[15%]"
            >
              <Image src="/images/bumper-notification.svg" alt="" width={430} height={63} className="w-full h-auto" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );

  return (
    <div ref={ref} className="mx-2 my-4 md:my-12">
      <div className="mx-auto max-w-grid">
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
          {ctaCol}
          {animationColumn}
        </div>
      </div>
    </div>
  );
};
